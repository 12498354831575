import React, { useRef } from 'react';
import clsx from 'clsx';
// styles
import styles from './fitness-mobile.module.css';
// components
import Layout from 'components/layout';
import GetConsultation from 'components/free-consultation';
import Footer from 'components/layout/footer';
import FAQ from 'components/faq';
import Booking from 'components/feedback';
import Recognition from 'components/recognition';
import loadable from '@loadable/component';
// sections
import Header from 'components/landing-pages/sections/header';
import Features from 'components/landing-pages/sections/features';
import WorkProcess from 'components/landing-pages/sections/work-process';
import Services from 'components/landing-pages/sections/services';
import { Betone, Ifit, Torwod } from 'components/carousel';
// constants
import { usePageData } from './constants';
// meta
import { meta } from 'metaData';

const SwiperSlider = loadable(() => import('components/swiper-slider'));

const FitnessMobileSolutionsVersionA = () => {
  const bookingRef = useRef<HTMLDivElement>(null);

  const pageData = usePageData();

  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout metaData={{ main: meta.fitnessMobileSolutionsA }} navBtnTextColor="var(--black-color)">
      <Header hideImg onClickCTA={handleClickCTA} data={pageData.header} />
      <main style={{ overflow: 'hidden' }}>
        <Features data={pageData.features} />
        <SwiperSlider>
          <Ifit />
          <Betone />
          <Torwod />
        </SwiperSlider>
        <Services data={pageData.services} />
        <WorkProcess data={pageData.workProcess} />
        <GetConsultation
          title="Hire dedicated developers team for your fitness app"
          linkText="Get a consultation"
          onClick={handleClickCTA}
        />
        <section ref={bookingRef} className={clsx('section', styles.recognition)}>
          <div className="inner">
            <h2 className="title">Why choose us</h2>
            <Recognition />
          </div>
        </section>
        <div className="inner">
          <FAQ data={{ list: pageData.faq }} />
        </div>
        <Booking scrollRef={bookingRef} />
      </main>
      <Footer />
    </Layout>
  );
};

export default FitnessMobileSolutionsVersionA;
