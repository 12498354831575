import React from 'react';
// assets
import startupServiceIcon from './assets/startup-service-icon.svg';
import healthServiceIcon from './assets/health-service-icon.svg';
import sportClubServiceIcon from './assets/sport-club-service-icon.svg';
import { graphql, useStaticQuery } from 'gatsby';

export const usePageData = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "fitness-mobile-header-bg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    header: {
      title: 'Mobile Solutions for the Fitness Industry',
      text: 'Let’s discover new points of differentiating your business! Brocoders will craft a health and wellness solution for a seamless yet meaningful experience for users. Activity tracking, data collection, health metrics prediction, and everything else that will make your customers’ fitness journey easier.',
      btnText: 'Get a quote',
      bgImg: data.bg.childImageSharp.fluid,
    },
    features: {
      title: 'Explore apps we built for the Fitness Industry',
      subtitle: 'Get your custom digital solution that will match your business needs perfectly',
      items: [
        {
          title: 'Workout and exercise apps',
          subtitle:
            'Roll out personal trainer apps, logbook apps, and workout fitness apps with device sync. Help users to stay on top of their workout, perform exercises correctly, and track progress.',
        },
        {
          title: 'Nutrition apps',
          subtitle:
            'Allow users to count calories and macronutrients and find recipes to hit their healthy nutrition goals.',
        },
        {
          title: 'Activity tracking apps',
          subtitle:
            'Integrate activity trackers (e.g., phones, wearables like fitness bands and smartwatches, etc.) to run reports and analyze data.',
        },
        {
          title: 'Applications for fitness clubs',
          subtitle:
            'The app can serve as a pass, attendance record, communication platform, virtual trainer in the club, among other functions.',
        },
        {
          title: 'Smart equipment apps',
          subtitle:
            'Monitor user activity with minimal entry actions - for example, use NFC technology on the fitness machine to record reps, sets, weight used, rest periods, etc.',
        },
        {
          title: 'Apps for disease management',
          subtitle:
            'Give the ability to manage and improve wellbeing, e.g., integrate exercise data with data on blood sugar and food intake for people with diabetes.',
        },
      ],
    },
    services: {
      title: 'Who Will Benefit From Our Services?',
      subtitle: 'Our services will be perfect for:',
      list: [
        {
          title: 'Startups',
          subtitle:
            'Thinking about moving your brilliant idea into the mobile segment? Let us build an app that scales with you, starting with an MVP and evolving into something bigger.',
          icon: startupServiceIcon,
        },
        {
          title: 'Health and fitness product companies',
          subtitle:
            'Our team will create an app that ties in with your products or a stand-alone app that puts your individual spin on what a fitness app should be.',
          icon: healthServiceIcon,
        },
        {
          title: 'Sport club owners',
          subtitle:
            'Build a digital liaison between customers and your business, automating your admin for employees and enabling membership management for customers.',
          icon: sportClubServiceIcon,
        },
      ],
    },
    workProcess: {
      title: 'Our Work Process',
      tabs: [
        {
          title: 'Free consultation',
        },
        {
          title: 'Discovery phase',
        },
        {
          title: 'Integration',
        },
        {
          title: 'Testing',
        },
        {
          title: 'Launch',
        },
        {
          title: 'Support',
        },
      ],
      tabsData: [
        {
          title: 'We’ll start by:',
          list: [
            'Determining the right service for your business',
            'Selecting the consultant that will guide you through the steps',
            'Establishing the timeline',
            'Reviewing essential information about your business and intentions',
          ],
        },
        {
          title: 'We create a shared understanding of the app by:',
          list: [
            'Researching your opportunities, competitors, and industry',
            'Analyzing your target audience, customer profiles, and market segments',
            'Matching your app with API/Service policy and requirements',
          ],
        },
        {
          title: 'We move on to enrich your app functionality through:',
          list: [
            'Making sure everything moves according to schedule',
            'Putting the right people for the task, such as frontend engineers and project managers',
            'Bringing the product to the end of the development cycle',
          ],
        },
        {
          title: 'Let’s see how the concept works in real-world settings by:',
          list: [
            'Checking the functionality of each unit',
            'Conducting stress and security tests and correcting weak links',
            'Confirming device and platform compatibility',
          ],
        },
        {
          title: 'Get ready for the public roll-out as we:',
          list: [
            'Submit the app to the target application stores',
            'Get the approval and finalize the details',
          ],
        },
        {
          title: 'We don’t leave your side after the app goes live and:',
          list: [
            'Provide ongoing maintenance and support',
            'Identify issues and implement modifications',
          ],
        },
      ],
    },
    faq: [
      {
        question: 'Is it expensive to build a fitness app?',
        answer:
          'The cost of developing an application is always affected by a set of factors. The complexity of functionality and design, requested features, the number of platforms to be developed, and the inclusion of third-party integrations - these are the most influential factors. Before stating the final cost, we need to learn as much information about your project and goals as possible.',
      },
      {
        question: 'Who is involved in developing a fitness app?',
        renderAnswer: () => (
          <>
            <p>The usual composition of a Brocoders mobile development team is:</p>
            <ul>
              <li>Project manager</li>
              <li>Business analyst</li>
              <li>Designer</li>
              <li>Developer</li>
              <li>QA-specialist</li>
            </ul>
          </>
        ),
      },
      {
        question: 'What are the steps for mobile app development?',
        renderAnswer: () => (
          <>
            <p>
              We implement a multi-step development approach with clear plans and responsibilities:
            </p>
            <ol>
              <li>Obtaining information about what is needed</li>
              <li>
                Market research (products, stakeholders, companies, industries) and business
                analysis
              </li>
              <li>Building an interactive model and design iterations</li>
              <li>Development process with ongoing reports</li>
              <li>QA, testing, and debugging of the release build</li>
              <li>Performance troubleshooting, optimization, and monitoring</li>
              <li>Final preparations and launch</li>
              <li>Ongoing maintenance and support</li>
            </ol>
          </>
        ),
      },
    ],
  };
};
